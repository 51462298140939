import { useContext } from "react";
import { Row, Col, Form, Card, Container, Button } from "react-bootstrap";

import { MainContext } from "../../context/MainContext";
import { LoginBoxStyles } from "./styles";

export const LoginBox = () => {
  const {
    loginWithGoogle,
    loginWithMicrosoft,
    loginWithEmailAndPassword,
    setEmail,
    setPassword,
  } = useContext(MainContext);

  return (
    <LoginBoxStyles className="py-5">
      <Card.Body>
        <Container fluid={true}>
          <Row>
            <Col md={9} className="mx-auto pt-3">
              <Row>
                <Col className="mb-3">
                  <Form.Control
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="input-text"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <Form.Control
                    type="password"
                    placeholder="Senha"
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="new-password"
                    className="input-text"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="auto" className="mb-2 mx-auto rememberMeSection">
                  <Form.Check type="checkbox" label={`Lembrar de mim`} />
                </Col>
              </Row>
              <Row>
                <Col xs="auto" className="mx-auto my-4 loginButtonSection">
                  <Button
                    className="btn-enter"
                    type="button"
                    onClick={() => loginWithEmailAndPassword()}
                  >
                    Entrar
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs="auto" className="mx-auto mb-2">
                  <span>Ou entrar com</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md="auto" className="my-2 ms-md-auto">
              <Button
                className="btn-acess"
                onClick={() => loginWithMicrosoft()}
              >
                <Row>
                  <Col xs={2} md={2} className="pr-none">
                    <img
                      className="icon-ms"
                      alt="Microsoft login"
                      src={"/icons/microsoft.svg"}
                    />
                  </Col>
                  <Col xs={9} md={9}>
                    <span>Microsoft</span>
                  </Col>
                </Row>
              </Button>
            </Col>
            <Col md="auto" className="my-2 me-auto">
              <Button
                className="btn-acess mx-auto"
                onClick={() => loginWithGoogle()}
              >
                <Row>
                  <Col xs={2}>
                    <img
                      className="icon-google"
                      alt="Google Login"
                      src={"/icons/google.svg"}
                    />
                  </Col>
                  <Col xs="9">
                    <span>Google</span>
                  </Col>
                  <div id="googleContainer" />
                </Row>
              </Button>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </LoginBoxStyles>
  );
};
