import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { msalConfig } from "./provider/microsoft/authConfig";
import { MainContextProvider } from "./context/MainContext";
import BrainzRoutes from "./BrainzRoutes";

const msalInstance = new PublicClientApplication(msalConfig);

export default function App() {
  return (
    <div className="App">
      <MsalProvider instance={msalInstance}>
        <MainContextProvider>
          <BrowserRouter>
            <BrainzRoutes />
          </BrowserRouter>
        </MainContextProvider>
      </MsalProvider>
    </div>
  );
}
