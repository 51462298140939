import styled from "styled-components";
import { Container, Card } from "react-bootstrap";

export const LoginStyles = styled(Container)`
  .base-row {
    min-height: 75vh;
  }
  .app-title {
    margin: 2rem 0 1rem;

    font-family: "Lato", sans-serif;
    font-size: 1.5rem;
    line-height: 28px;

    color: #6468c8;
    font-weight: 600;
  }

  h4 {
    font-family: "Lato", sans-serif;
    color: #514f4e;
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }

  .rememberMeSection,
  .loginButtonSection {
    width: 100%;
  }

  .loginButtonSection {
    button {
      width: 100%;
      height: 44px;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 1025px) {
    .app-title {
      font-size: 1rem;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 2rem;

  img {
    max-height: 4vmin;
  }

  @media screen and (max-width: 1025px) {
    margin-top: 2rem;

    img {
      max-height: 40px;
    }
  }
`;
export const LoginBoxStyles = styled(Card)`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 36px #00000029;
  border-radius: 20px !important;
  border: none !important;
  opacity: 1;
  width: 70vh;
  max-width: 100vw;

  span {
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 1rem !important;
    font-weight: bold !important;
    letter-spacing: 0px;
    color: #8d8d8d;
    opacity: 1;
  }
  .form-check {
    max-width: 50vw;
    margin-right: 0px;
  }
  .input-text {
    background-color: #fdfdfd !important;
    &::placeholder {
      text-align: left;
      font: normal normal normal 16px/19px Lato;
      letter-spacing: 0px;
      color: #cbcaca !important;
      opacity: 1;
    }
    &:hover {
      background-color: #ffffff !important;
    }
  }
  .btn-enter {
    width: 131px;
    height: 40px;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 1rem !important;
    font: normal normal bold;
    letter-spacing: 0px;
    opacity: 1;
    background: 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #6468c86c !important;
    border-radius: 8px;
    opacity: 1;
  }

  .btn-acess {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #e2e2e2;
    border: none;
    border-radius: 8px;
    opacity: 1;
    min-height: 41px;
    min-width: 150px;
    width: 95%;
    &:hover {
      box-shadow: 0px 3px 6px #80808077;
    }
    span {
      text-align: center;
      font-family: "Lato", sans-serif;
      font-size: 1rem !important;
      letter-spacing: 0px;
      font-weight: bold;
      opacity: 1;
      color: #6468c8;
    }
    .icon-google {
      max-width: 25px;
    }

    .icon-ms {
      min-width: 23px;
      max-width: 25px;
    }
  }

  .btn-inner--text {
    color: #6468c8;
  }

  input:checked {
    background-color: #6468c8 !important;
  }
  .btn-enter {
    background: #6468c8 !important;
    color: #ffffff !important;
  }

  .btn-enter:hover {
    background: #3e41ad !important;
  }

  .account-options {
    color: #6468c8;
  }
  .account-options:hover {
    color: #292d9c;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #6468c8 !important;
    background-color: #6468c8 !important;
  }

  @media screen and (max-width: 1025px) {
    width: 100%;
  }
`;

export const FooterStyles = styled.footer`
  position: absolute;
  bottom: 0px;
  width: 100%;
  color: #969696;

  text-align: center;
`;
