import { AxiosResponse } from "axios";
import {
  AccountInterface,
  ApiPaginatedResponse,
  ApiResponse,
} from "@brainz_group/webappframework";

import { AccountApplicationViewModel } from "../domain/viewModels/AccountApplicationViewModel";
import { AuthorizationPayload } from "../domain/payloads/AuthorizationPayload";
import { AuthorizationResponseViewModel } from "../domain/viewModels/AuthorizationResponseViewModel";

export default class AccountService {
  public async getApplications(): Promise<
    ApiPaginatedResponse<AccountApplicationViewModel> | undefined
  > {
    try {
      let endpoint = `/Application/GetApplicationsAvailableLogin`;
      let response: AxiosResponse<
        ApiPaginatedResponse<AccountApplicationViewModel>
      > = await AccountInterface.get(endpoint);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
  public async authorization(
    data: AuthorizationPayload
  ): Promise<ApiResponse<AuthorizationResponseViewModel> | undefined> {
    try {
      let endpoint = `/Account/Authorization`;
      let response = await AccountInterface.post(endpoint, data);

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
}
