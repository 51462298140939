import { useContext } from "react";
import { Row, Col } from "react-bootstrap";

import { LoginProps } from "../../domain/props/LoginProps";
import UndentifiedUserModal from "../../components/modal/UndentifiedUserModal";
import { MainContext } from "../../context/MainContext";

import { LoginBox } from "./LoginBox";
import { Header, LoginStyles, FooterStyles } from "./styles";

function Login({ applicationName }: LoginProps) {
  const { showUndentifiedUserModal } = useContext(MainContext);

  const handleGetCurrenYear = () => {
    return new Date()?.getFullYear();
  };

  return (
    <section className="accountContainer">
      <Header>
        <img className="brainz-logo" src={"/logo/brainz-logo.png"} alt="" />
      </Header>
      <LoginStyles fluid={true}>
        <Row className="base-row">
          <Col sm={11} md={8} lg={6} xl={5} className="mx-auto my-auto">
            <Row>
              <Col className="text-center">
                <h5 className="text-center app-title">{applicationName}</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4 className="text-center">Seja bem-vindo!</h4>
              </Col>
            </Row>
            <Row>
              <Col md="auto" className="mx-auto">
                <LoginBox />
              </Col>
            </Row>
          </Col>
        </Row>

        {showUndentifiedUserModal && <UndentifiedUserModal />}
      </LoginStyles>
      <FooterStyles>
        <p className="bigbrain-footer">
          Brainz Group - {handleGetCurrenYear()} ©
        </p>
      </FooterStyles>
    </section>
  );
}

export default Login;
