import { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import { MainContext } from "./context/MainContext";
import Login from "./screens/login/Login";

function BrainzRoutes() {
  const { applications } = useContext(MainContext);

  return (
    <>
      <Routes>
        {applications?.map((application) => {
          return (
            <Route
              key={application.code}
              path={"/" + application.code}
              element={
                <Login
                  code={application.code}
                  applicationId={application.id}
                  applicationName={application.name}
                />
              }
            />
          );
        })}
      </Routes>
    </>
  );
}

export default BrainzRoutes;
