import { useContext } from "react";
import styled from "styled-components";
import { Button, Modal, Col, Row } from "react-bootstrap";

import { MainContext } from "../../context/MainContext";

const UndentifiedUserModal = () => {
  const { showUndentifiedUserModal, setShowUndentifiedUserModal } =
    useContext(MainContext);

  return (
    <ModalStyles show={showUndentifiedUserModal} centered>
      <Modal.Body>
        <Row>
          <Col>
            <h2 className="text-center">Ops!</h2>
          </Col>
        </Row>
        <Row className="w-70 mx-auto py-3">
          <Col>
            <h5 className="text-center error-text">
              Usuário não identificado, tente novamente mais tarde.
            </h5>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mx-auto">
            <Button
              className="w-100 my-4 btn-enter"
              type="button"
              onClick={() => setShowUndentifiedUserModal(false)}
            >
              Voltar
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </ModalStyles>
  );
};

const ModalStyles = styled(Modal)`
  .btn-enter {
    background: #6468c8 !important;
    color: #ffffff !important;
  }

  .btn-enter:hover {
    background: #3e41ad !important;
  }
`;
export default UndentifiedUserModal;
