import { createContext, ReactNode, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { PageLoader } from "@brainz_group/webappframework";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Buffer } from "buffer";

import { AuthorizationTypeEnum } from "../domain/enumerators/AuthorizationTypeEnum";
import { AuthorizationPayload } from "../domain/payloads/AuthorizationPayload";
import { AccountApplicationViewModel } from "../domain/viewModels/AccountApplicationViewModel";
import { AuthorizationResponseViewModel } from "../domain/viewModels/AuthorizationResponseViewModel";
import { auth, googleAuthProvider } from "../provider/Firebase";
import AccountService from "../service/AccountService";

const accountService = new AccountService();

interface MainContextData {
  applications: AccountApplicationViewModel[];

  loginWithGoogle: () => void;
  loginWithMicrosoft: () => void;
  loginWithEmailAndPassword: () => void;

  showUndentifiedUserModal: boolean;
  setShowUndentifiedUserModal: React.Dispatch<React.SetStateAction<boolean>>;

  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;

  reRender: () => void;
}

interface MainContextProviderProps {
  children: ReactNode;
}

export const MainContext = createContext({} as MainContextData);

export const MainContextProvider: React.FC<MainContextProviderProps> = ({
  children,
}) => {
  const { instance } = useMsal();

  const loginRequest = {
    scopes: ["User.Read"],
    prompt: "select_account",
  };

  const [isLoading, setIsLoading] = useState(true);
  const [showUndentifiedUserModal, setShowUndentifiedUserModal] =
    useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [reload, callReload] = useState(false);

  const [applications, setApplications] = useState<
    AccountApplicationViewModel[]
  >([]);

  useEffect(() => {
    pageLoad();
  }, []);

  async function pageLoad() {
    let applications = await accountService.getApplications();

    if (applications?.success) {
      setApplications(applications.result);
    }

    setIsLoading(false);
  }

  async function loginWithGoogle() {
    try {
      setIsLoading(true);

      googleAuthProvider.setCustomParameters({
        prompt: "select_account",
      });

      let result = await signInWithPopup(auth, googleAuthProvider);

      const credential = GoogleAuthProvider.credentialFromResult(result);

      let application = applications.filter((app) => {
        return (
          app.code ===
          window.location.pathname.replace("/", "").toLocaleLowerCase()
        );
      })[0];

      if (credential != null) {
        let payload: AuthorizationPayload = {
          applicationId: application.id,
          type: AuthorizationTypeEnum.Google,
          login: result.user.email!,
          accessToken: credential.idToken!,
        };

        await handleAuthorization(payload);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  async function loginWithMicrosoft() {
    try {
      setIsLoading(true);

      let resp = await instance.acquireTokenPopup(loginRequest);

      let application = applications.filter((app) => {
        return (
          app.code ===
          window.location.pathname.replace("/", "").toLocaleLowerCase()
        );
      })[0];

      let payload: AuthorizationPayload = {
        applicationId: application.id,
        type: AuthorizationTypeEnum.Microsoft,
        login: resp.account!.username,
        accessToken: resp.accessToken,
      };

      handleAuthorization(payload);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  async function loginWithEmailAndPassword() {
    try {
      setIsLoading(true);

      let application = applications.filter((app) => {
        return (
          app.code ===
          window.location.pathname.replace("/", "").toLocaleLowerCase()
        );
      })[0];

      let encodedPassword = Buffer.from(password).toString("base64");
      let payload: AuthorizationPayload = {
        applicationId: application.id,
        type: AuthorizationTypeEnum.Internal,
        login: email,
        password: encodedPassword,
      };

      handleAuthorization(payload);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  async function handleAuthorization(payload: AuthorizationPayload) {
    try {
      let loginResponse = await accountService.authorization(payload);

      if (loginResponse !== undefined) {
        if (loginResponse.success) {
          handleSuccessfullLogin(loginResponse.result!);
        }
      } else {
        setShowUndentifiedUserModal(true);
        setIsLoading(false);
      }
    } catch (error) {
      throw error;
    }
  }

  function handleSuccessfullLogin(data: AuthorizationResponseViewModel) {
    try {
      let queryParamToken = `?${String(
        process.env.REACT_APP_BRAINZ_NAME_TOKEN
      )}=${data.token}`;
      if (process.env.NODE_ENV === "development") {
        window.location.href = "http://localhost:3000" + queryParamToken;
      } else {
        window.location.href = data.redirectDomain + queryParamToken;
      }
    } catch (error) {
      throw error;
    }
  }

  function reRender() {
    callReload(!reload);
  }

  return (
    <MainContext.Provider
      value={{
        showUndentifiedUserModal,
        setShowUndentifiedUserModal,
        setEmail,
        setPassword,
        loginWithGoogle,
        loginWithMicrosoft,
        loginWithEmailAndPassword,
        reRender,
        applications,
      }}
    >
      {children}
      {isLoading && (
        <PageLoader spinnerColor={String(process.env.REACT_APP_MAIN_COLOR)} />
      )}
    </MainContext.Provider>
  );
};
